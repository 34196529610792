export const authRoutes = [
    {
        path: '/',
        redirect: {
            name: "login"
        }
    },

    {
        path: "/user/login",
        name: "login",
        component: () => import('./Login')
    },
    {
      path: '/signin-callback',
      name: 'signin-callback',
      component: () => import('./SignInRedirectCallback')
    },
    {
      path: '/signout-callback',
      name: 'signout-callback',
      component: () => import('./SignOutRedirectCallback')
  },
  {
    path: '/silent-refresh',
    name: 'silent-refresh',
    component: () => import('./SilentRefresh')
  }
]